export default class EntryTableItem {
    entryId = 0;
    entryStatus = 0;
    entryName = "";
    entryRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
