import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import EntryTableItem from "@/entity/aftersale/inspection/EntryTableItem";
import API from "@/api/aftersale/inspection/entry";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";

@Options({
    name: "app-aftersale-inspection-entry",
    components: {
        "app-draggable-listview": DraggableListview,
    },
})
export default class Entry extends BaseTableMixins<EntryTableItem> implements Partial<BaseTableInterface<EntryTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new EntryTableItem();
        this.editModalItem = new EntryTableItem();
    }

    public activated(): void {
        this.getList(1);
    }
    //后端API
    public getApi(): any {
        return API;
    }
    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new EntryTableItem();
        this.editModalItem = new EntryTableItem();
        this.editModalItem.entryStatus = 1;
        this.editModalTitle = "添加巡检项";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.entryName)) return "巡检项不能为空！";
        return null;
    }
    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改巡检项";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.entryName)) return "巡检项不能为空！";
        return this.addSubmitChecking();
    }
    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "巡检项状态",
            message: "巡检项: " + this.editModalItem.entryName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.entryStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.entryStatus = this.editModalItem.entryStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.entryName)) return "巡检项不能为空！";
        return null;
    }
    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除巡检项",
            message: "巡检项: " + this.editModalItem.entryName + "<br>请确认删除？",
        };
    }
}
